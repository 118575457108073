
<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <v-main>
        <router-view/>
    </v-main>
    <footer class="footer_m">
      
      <div class="footer_div_2">
        <div id="block-abilegaltext">
        <button class="legal_accordion" style="background-color: #ffff00; color: #000000;" @click="anuncioLegal = !anuncioLegal ">CANALES DE ATENCIÓN Y RESPUESTA A PETICIONES, CONSULTAS, QUEJAS Y RECLAMOS DE TITULARES DE DATOS PERSONALES</button>
        <div class="legal_panel " style="background-color: rgb(204, 204, 204); color: rgb(0, 0, 0);" v-show="anuncioLegal">
            <p>
              Procedimiento para Atención y Respuesta a Peticiones, Consultas, Quejas y Reclamos de los Titulares de Datos Personales: Los Titulares de Datos Personales trataos por LA COMPAÑÍA tienen derecho a acceder a sus Datos Personales y a los detalles de dicho Tratamiento, así como a rectificarlos y actualizarlos en caso de ser inexactos o a solicitar su eliminación cuando consideren que resultan ser excesivos o innecesarios para las finalidades que justificaron su obtención u oponerse al Tratamiento de los mismos para fines específicos. Las vías que se han implementado para garantizar el ejercicio de dichos derechos a través de la presentación de la solicitud respectiva son: Comunicación dirigida a Cervecería Modelo de México, S. de R.L. de C.V., área de compliance, ubicada en la calle Cerrada Palomas # 22, piso 6, colonia Reforma Social, alcaldía Miguel Hidalgo, C.P. 11650, Ciudad de México, México, o envío de solicitud al correo electrónico datos.legal@ab-inbev.com
            </p>
        </div>
      </div>
        <div class="footer_terminos">
          <ul>
            <li>
              <a href="https://portal.grupomodelo.com/es/terminos.html" target="_blank"> Términos y condiciones de uso </a>
            </li>
            <li>
              <a href="https://www.grupomodelo.com/abilegal/politica-deproteccion-de-datos-personales" target="_blank"> Aviso de privacidad </a>
            </li>
            <li>
              <a href="https://portal.grupomodelo.com/es/noticias/contactanos" target="_blank"> Contáctenos </a>
            </li>
            <li>
              <a href="https://www.tapintoyourbeer.com/" target="_blank"> Hablemos de alcohol </a>
            </li>
            <li>
              <a href="https://www.ab-inbev.com/" target="_blank"> ANHEUSER-BUSCH INBEV© 2022 </a>
            </li>
          </ul>
        </div>
        <div class="footer_disclaimer">
          <p>TODO CON MÉDIDA. TODOS LOS DERECHOS RESERVADOS 2022. NO COMPARTAS ESTE CONTENIDO CON MENORES DE EDAD</p>
        </div>
      </div>
  </footer>

  </v-app>
</template>

<script>

export default {
  name: 'App',
  computed:{
    theme() {
      return 'light';
    }
  },

  data: () => ({
    anuncioLegal: false
  }),
};
</script>
<style lang="scss">
  @font-face {
    font-family: 'Open Sans';
    src: url('/MonumentExtended-Regular.otf') format('opentype');
  }
    .v-application {
      font-family: body-font-family, Open Sans !important;
      .title { 
          font-family: title-font, Open Sans !important;
        }
    }
    html{
      overflow-x: hidden;
    } 
    body{
      overflow-x: hidden;
    }
    div#onetrust-consent-sdk {
      font-family: 'Open Sans';
      font-size: 0.8em;
    }

    //estilos footer
.footer_m {
  width: 100%;
}
.footer_div_2 {
background-color: black;
padding: 2rem;
}

.footer_terminos ul {
display: flex;
justify-content: space-around;
}

.footer_terminos ul li {
list-style: none;
}

.footer_terminos ul li a {
color: #fff;

text-decoration: none;
font-weight: 500;
font-size: .8rem;
cursor: pointer;
text-transform: uppercase;
position: relative;
z-index: 15;
}

.footer_disclaimer {
display: flex;
justify-content: center;
margin-top: 1.5rem;
}

.footer_disclaimer p {
color: #fff;

text-decoration: none;
font-size: .8rem;
}

@media (max-width: 970px) {


.footer_div_budlight {
    display: grid;
    grid-template-rows: 3.5rem 1rem;
}

.footer_div_social_budlight {
    width: 100%;
    justify-content: end;
}

.footer_div_logo_budlight {
    width: 100%;
    margin-right: 0;
    grid-row: 1 / 2;
    display: flex;
    justify-content: center;
    margin-top: .4rem;
}

.footer_div_logo_budlight img {
    width: 70%;
}

.footer_div_becks {
    display: grid;
    grid-template-rows: 3.5rem 1rem;
}

.footer_div_socials_becks {
    width: 100%;
    justify-content: center;
}

.footer_div_logo_becks {
    width: 90%;
    grid-row: 1 / 2;
    display: flex;
    justify-content: center;
    margin-left: 1rem;
    margin-top: .4rem;
}

.footer_div_logo_becks img {
    width: 65%;
    margin-right: 1.5rem;
}

.footer_div_social_budlight a {
    margin-right: 1rem;
}

.footer_div_social_budlight a img {
    width: 55%;
}

.footer_div_socials_becks a {
    margin-left: 1rem;
}

.footer_div_socials_becks a img {
    width: 55%;
}

.footer_div_2 {
    padding: 1rem 1.5rem 1.5rem;
}

.footer_terminos ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.footer_terminos ul li {
    width: 50%;
    text-align: center;
    padding: .2rem 0;
}

.footer_terminos ul li:nth-child(5) {
    width: 100%;
}

.footer_terminos ul li a {
    font-size: .6rem;
}

.footer_disclaimer p {
    font-size: .6rem;
    text-align: center;
}

.footer_disclaimer {
    margin-top: 1rem;
}
}


.block.block-abi-legal-d8{
  width: 100%;
}

.legal_accordion {
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: center;
  outline: none;
  font-size: 15px;
  font-weight: bold;
  transition: 0.4s;
}

.legal_panel {
  overflow: hidden;
  font-size: 13px;

}
</style>
