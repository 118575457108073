import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/Edad',
    name: 'Edad',
    component: () => import('../views/Edad.vue')
  },
  {
    path: '/Justificacion',
    name: 'Justificacion',
    component: () => import('../views/Justificacion.vue')
  },
  {
    path: '/Iniciar-sesion',
    name: 'IniciarSesion',
    component: () => import('../views/IniciarSesion.vue')
  },
  {
    path: '/Manifiesto',
    name: 'Manifiesto',
    component: () => import('../views/Manifiesto')
  },
  {
    path: '/AgregarUnaFoto',
    name: 'AgregarUnaFoto',
    component: () => import('../views/AgregarUnaFoto.vue')
  },
  {
    path: '/Foto',
    name: 'Foto',
    component: () => import('../views/Foto.vue')
  },
  {
    path: '/FotoTerminada',
    name: 'FotoTerminada',
    component: () => import('../views/FotoTerminada.vue')
  },
  {
    path: '/Participaciones',
    name: 'Participaciones',
    component: () => import('../views/Participaciones.vue')
  },
  {
    path: '/Playlist',
    name: 'Encuesta',
    component: () => import('../views/Encuesta.vue')
  },
  {
    path: '/Infobecks',
    name: 'Infobecks',
    component: () => import('../views/Infobecks.vue')
  },
  {
    path: '/Eventos',
    name: 'Fiestas',
    component: () => import('../views/Fiestas.vue')
  },
  {
    path: '/Publicados',
    name: 'Publicados',
    component: () => import('../views/Publicados')
  },
  {
    path: '/FAQs',
    name: 'FAQs',
    component: () => import('../views/FAQs.vue')
  },
  {
    path: '/Galeria',
    name: 'Galeria',
    component: () => import('../views/Galeria.vue')
  },
  {
    path: '/Galeria/:uuid',
    name: 'Galeria2',
    component: () => import('../views/Galeria.vue')
  },
  {
    path: '/Registro',
    name: 'IniciarSesions',
    component: () => import('../views/IniciarSesions.vue')
  },
  { path: "*", component: () => import('../views/Home.vue') }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
